import React from 'react'
import { useTranslation } from 'react-i18next'

function About() {
  const { t } = useTranslation('company-details')
  return (
    <div className="about">
      <p>
        {t(
          'Auxasphere is a Franco-Swiss group born of the merger of the expertise acquired by the team in sensitive, high value-added digital transactions (payments) with major security, traceability and sovereignty issues.',
        )}
      </p>
      <p>
        {t(
          'It also incorporates a wide range of skills in intelligent embedded systems and connected objects, particularly in the healthcare sector.',
        )}
      </p>
    </div>
  )
}

export default About
