import React from 'react'
import { useTranslation } from 'react-i18next'

function AdvisoryBoard() {
  const { t } = useTranslation('company-details')

  return (
    <div className="advisory-board">
      <p>
        {t(
          'The members of the strategic advisory board are recognised for their business expertise and knowledge of their industry at an international level.',
        )}
      </p>
      <p className="mb-1em">
        {t(
          'Its mission is to provide senior management with information by monitoring the sector, and to contribute to strategic thinking on the long-term vision and structural evolution of the digital technology market and its impact on society.',
        )}
      </p>
      <p className="mosaic-title">
        {t('The Strategic Advisory Board is structured into three areas of expertise')}
      </p>
      <div className="mosaic-container">
        <div className="mosaic">
          <img src="/images/advisory_1.jpg" />
          <div className="desc-container">
            <p className="title">{t('Regulatory Expertise:')}</p>
            <p className="content">
              {t('Health - Data Protection - Information Systems')}
            </p>
          </div>
        </div>
        <div className="mosaic">
          <img src="/images/advisory_2.jpg" />
          <div className="desc-container">
            <p className="title">{t('Medical expertise:')}</p>
            <p className="content">{t('Several specialities in Europe')}</p>
          </div>
        </div>
        <div className="mosaic">
          <img src="/images/advisory_3.jpg" />
          <div className="desc-container">
            <p className="title">{t('Technological expertise:')}</p>
            <p className="content">
              {t(
                'Identity, Telecoms, Security, Artificial Intelligence, e-administration, Post quantum encryption, Blockchain',
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvisoryBoard
