import React from 'react'
import './punchline.scss'

interface Props {
  title: string
  desc: string
  background: string
}

function Punchline({ title, desc, background }: Props) {
  return (
    <section
      className="punchline section-content"
      style={{ backgroundImage: `url('/images/${background}')` }}
    >
      <h1 className="punchline-title">{title}</h1>
      <h2 className="punchline-desc">{desc}</h2>
    </section>
  )
}

export default Punchline
