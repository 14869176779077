import { configureStore } from '@reduxjs/toolkit'
import demandContactReducer from './components/contact-form/demandContactSlice'

export const store = configureStore({
  reducer: {
    demandContact: demandContactReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
