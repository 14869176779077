import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'
import Home from './pages/home/Home'
import './index.scss'
import './i18n'
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom'
import CompanyDetails from './pages/company-details/CompanyDetails'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<CompanyDetails defaultOpen="about-us" />} />
          <Route path="/investors" element={<CompanyDetails defaultOpen="investors" />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)
