import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Upload,
  message,
  Select,
  Checkbox,
  Row,
  Col,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  sendDemandContact,
  idledemandContactStatus,
  setVisible,
} from '../contact-form/demandContactSlice'
import './contact-form-modal.scss'
import { AppDispatch, RootState } from '../../store'
import { useTranslation } from 'react-i18next'

const { Option } = Select

export enum FormType {
  COMMERCIAL = 'COMMERCIAL',
  RH = 'RH',
}

function ContactFormModal() {
  const { t } = useTranslation('home')
  const [selectedFile, setSelectedFile] = useState(null)
  const [formRH, setFormRH] = useState(true)
  const dispatch = useDispatch<AppDispatch>()
  const [form] = Form.useForm()
  const demandContactStatus = useSelector(
    (state: RootState) => state.demandContact.demandContactStatus,
  )

  const modalVisible = useSelector((state: RootState) => state.demandContact.visible)

  const handleCancel = () => {
    dispatch(setVisible(false))
    setSelectedFile(null)
    form.resetFields()
  }

  useEffect(() => {
    switch (demandContactStatus) {
      case 'success':
        message.success(t('Your demand of contact has been successfully sent.'))
        dispatch(setVisible(false))
        setSelectedFile(null)
        form.resetFields()
        break
      case 'error':
        message.error(t('An error occurred.'))
        break
    }

    return () => {
      dispatch(idledemandContactStatus())
    }
  }, [demandContactStatus])

  const handleContactSubmit = async (values: {
    firstName: string
    name: string
    email: string
    phone: string
    message: string
    contact: FormType
  }) => {
    const trimmedEmail = values.email.trim()
    const formData = {
      firstName: values.firstName,
      name: values.name,
      email: trimmedEmail,
      phone: values.phone,
      message: values.message,
      file: selectedFile ? [selectedFile] : [],
      contact: values.contact,
    }
    dispatch(sendDemandContact(formData))
  }

  const props = {
    beforeUpload: (file: any) => {
      if (file.type === 'application/pdf') {
        setSelectedFile(file)
      } else {
        message.error(t('NOT_PDF', { filename: file.name }))
      }
      return false
    },
    fileList: selectedFile ? [selectedFile] : [],
    onRemove: () => {
      setSelectedFile(null)
    },
  }

  const handleContactChange = (value: string) => {
    setFormRH(value === FormType.RH)
  }

  return (
    <Modal
      title={t('Contact us')}
      open={modalVisible}
      onCancel={handleCancel}
      footer={null}
      className="contact-form-modal"
      centered
    >
      <Form
        form={form}
        name="contact_form"
        onFinish={handleContactSubmit}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          contact: FormType.RH,
        }}
      >
        <Form.Item
          label={t('Contact')}
          name="contact"
          rules={[{ required: true, message: t('Please select a contact type') }]}
        >
          <Select onChange={handleContactChange}>
            <Option value={FormType.COMMERCIAL}>{t('Commercial')}</Option>
            <Option value={FormType.RH}>{t('HR')}</Option>
          </Select>
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item
              label={t('First Name')}
              name="firstName"
              rules={[{ required: true, message: t('Please input your first name') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label={t('Name')}
              name="name"
              rules={[{ required: true, message: t('Please input your last name') }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t('Email')}
          name="email"
          rules={[
            { required: true, message: t('Please input your email') },
            {
              type: 'email',
              message: t('Please enter a valid email address'),
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('Mobile phone')} name="phone">
          <Input />
        </Form.Item>
        {formRH && (
          <Form.Item label={t('Document (PDF only)')} name="file">
            <Upload maxCount={1} {...props}>
              <Button icon={<UploadOutlined />}>{t('Click to upload')}</Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          label={t('Message')}
          name="message"
          rules={[{ required: true, message: t('Please input your message') }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t('Agreement')}
          name="agree"
          valuePropName="checked"
          rules={[
            {
              required: true,
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(t('This field is required')),
            },
          ]}
        >
          <Checkbox>
            {formRH ? (
              <>
                {t(
                  'By submitting this form, I agree that the personal data entered will be used exclusively to contact me regarding my application.',
                )}
              </>
            ) : (
              <>
                {t(
                  'By submitting this form, I agree that the personal data entered will be used exclusively to contact me regarding my request.',
                )}
              </>
            )}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={demandContactStatus === 'loading'}
          >
            {t('Send')}
          </Button>
        </Form.Item>
      </Form>
      {formRH ? (
        <p className="info">
          {t(
            'Only authorized Auxasphere personnel will have access to your personal data in connection with your application. In the event of an unsuccessful application, Auxasphere will destroy the personal data collected in connection with the application.',
          )}
        </p>
      ) : (
        <p className="info">
          {t(
            'Only authorized Auxasphere personnel will have access to your personal data in connection with your request.',
          )}
        </p>
      )}
    </Modal>
  )
}

export default ContactFormModal
