import React from 'react'
import { Collapse } from 'antd'
import { CollapseProps } from 'antd/lib/collapse'
import Header from '../../components/layout/Header'
import MainBanner from '../../components/carousels/MainBanner'
import { useTranslation } from 'react-i18next'
import About from '../../components/about/About'
import Shareholders from '../../components/shareholders/Shareholders'
import AdvisoryBoard from '../../components/advisory-board/AdvisoryBoard'
import Commitments from '../../components/commitments/Commitments'
import './company-details.scss'
import Footer from '../../components/layout/Footer'

interface Props {
  defaultOpen: string
}

function CompanyDetails({ defaultOpen }: Props) {
  const { t } = useTranslation('company-details')

  const items: CollapseProps['items'] = [
    {
      key: 'about-us',
      label: <h2 id="about-us">{t('Our company')}</h2>,
      children: <About />,
      onClick: () =>
        setTimeout(() => {
          document.getElementById('about-us')?.scrollIntoView({ behavior: 'smooth' })
        }, 200),
    },
    {
      key: 'investors',
      label: <h2 id="investors">{t('Our shareholders')}</h2>,
      children: <Shareholders />,
      onClick: () =>
        setTimeout(() => {
          document.getElementById('about-us')?.scrollIntoView({ behavior: 'smooth' })
        }, 200),
    },
    {
      key: 'advisory-board',
      label: <h2 id="advisory-board">{t('Our strategic advisory board')}</h2>,
      onClick: () =>
        setTimeout(() => {
          document.getElementById('investors')?.scrollIntoView({ behavior: 'smooth' })
        }, 200),
      children: <AdvisoryBoard />,
    },
    {
      key: 'our-commitments',
      label: <h2 id="our-commitments">{t('Our commitments')}</h2>,
      onClick: () =>
        setTimeout(() => {
          document
            .getElementById('advisory-board')
            ?.scrollIntoView({ behavior: 'smooth' })
        }, 200),
      children: <Commitments />,
    },
  ]

  return (
    <div className="company-details">
      <Header />
      <MainBanner />
      <section className="section-content section-content--small">
        <Collapse accordion items={items} ghost defaultActiveKey={defaultOpen} />
      </section>
      <Footer />
    </div>
  )
}

export default CompanyDetails
