import React from 'react'
import { useTranslation } from 'react-i18next'
import './commitments.scss'

function Commitments() {
  const { t } = useTranslation('company-details')

  return (
    <div className="commitments">
      <p className="mosaic-title">{t('3 requirements to be promoted*')}</p>
      <div className="mosaic-container">
        <div className="mosaic">
          <img src="/images/commitments_1.jpg" />
          <div className="desc-container">
            <p className="title">{t('Data use compliance')}</p>
          </div>
        </div>
        <div className="mosaic">
          <img src="/images/commitments_2.jpg" />
          <div className="desc-container">
            <p className="title">{t('Native privacy')}</p>
          </div>
        </div>
        <div className="mosaic">
          <img src="/images/commitments_3.jpg" />
          <div className="desc-container">
            <p className="title">{t('Minimising climate impact')}</p>
          </div>
        </div>
      </div>
      <p className="mosaic-title mt-1em">
        {t(
          'We are committed to sharing these values with our employees, shareholders, partners and customers',
        )}
      </p>
      <p className="more-info">
        {t(
          '* Extract, 17 UN sustainable development goals, used in government projects around the world',
        )}
      </p>
    </div>
  )
}

export default Commitments
