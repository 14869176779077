import React from 'react'
import { useTranslation } from 'react-i18next'

function Shareholders() {
  const { t } = useTranslation('company-details')

  return (
    <div className="shareholders">
      <p className="mb-1em">
        {t('The company is majority-owned by its founding partners.')}
      </p>
      <p>
        {t(
          'Our other shareholders include a number of prominent European figures and family offices whose shared values are ethics and a strong commitment to the development of a responsible sovereign digital economy.',
        )}
      </p>
    </div>
  )
}

export default Shareholders
