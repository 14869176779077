import React from 'react'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguageIcon from './LanguageIcon'
import './language-switch.scss'
import { BsChevronDown } from 'react-icons/bs'

interface Props {
  light?: boolean
  disablePreferencesFetch?: boolean
}

export function LanguageSwitch({ light }: Props) {
  const { i18n } = useTranslation([])

  function onLanguageChanged(info: { key: string }) {
    i18n.changeLanguage(info.key)
  }

  const languagesList = [
    {
      label: 'English',
      key: 'en',
      country_code: 'GB',
    },
    // {
    //   label: 'Français',
    //   key: 'fr',
    //   icon: <LanguageIcon language="fr" />,
    //   country_code: 'FR',
    // },
  ]

  const menu = (
    <Menu onClick={onLanguageChanged} defaultSelectedKeys={[i18n.language]} selectable>
      {languagesList.map((lang) => (
        <Menu.Item key={lang.key}>
          <span>{lang.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} className="language-dropdown">
      <div className="d-flex language-icon">
        <span className={'language-text d-flex d-flex-middle'}>
          <span className="mt-0_1em">{i18n.language.toUpperCase()}</span>
          <BsChevronDown />
        </span>
      </div>
    </Dropdown>
  )
}
