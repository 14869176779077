import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import frFR from 'antd/locale/fr_FR';
import enGB from 'antd/locale/en_GB';
import homeEN from './trans/home.en.json'
import homeFR from './trans/home.fr.json'
import secureDataFlowEN from './trans/secure-data-flow.en.json'
import secureDataFlowFR from './trans/secure-data-flow.fr.json'
import securityScalabilityEN from './trans/security-scalability.en.json'
import securityScalabilityFR from './trans/security-scalability.fr.json'
import listCardsEN from './trans/list-cards.en.json'
import listCardsFR from './trans/list-cards.fr.json'
import partnershipEN from './trans/partnership.en.json'
import partnershipFR from './trans/partnership.fr.json'
import { Locale } from 'antd/lib/locale';

export type LocaleType = "fr" | "en"


/**
 * Get the locale ant object from the locale string
 * @param locale i18n locale string
 * @returns Locale ant object
 */
export const antLocale: {[key: string]: Locale} = {
  "fr": frFR,
  "en": enGB,
}

export const resources = {
  en: {
    'home': homeEN,
    'secure-data-flow': secureDataFlowEN,
    'security-scalability': securityScalabilityEN,
    'list-cards': listCardsEN,
    'partnership': partnershipEN
  },
  fr: {
    'home': homeFR,
    'secure-data-flow': secureDataFlowFR,
    'security-scalability': securityScalabilityFR,
    'list-cards': listCardsFR,
    'partnership': partnershipFR
  },
} as const;

i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("settingsLng") || "en",
    fallbackLng: 'en',
    resources,
    nsSeparator: false
  })


i18n.on("languageChanged", lng => {
  localStorage.setItem("settingsLng", lng)
})

export default i18n
