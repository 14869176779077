import React from 'react'
import './home.scss'
import { useTranslation } from 'react-i18next'
import Header from '../../components/layout/Header'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import MainBanner from '../../components/carousels/MainBanner'
import Punchline from '../../components/Punchline'
import SolutionCarousel from '../../components/carousels/SolutionCarousel'
import PartnershipCarousel from '../../components/carousels/PartnershipCarousel'
import ListCards from '../../components/list-cards/ListCards'
import SecureDataFlowSection from '../../components/section/SecureDataFlowSection'
import PropositionSection from '../../components/section/PropositionSection'
import Footer from '../../components/layout/Footer'
import SecurityScalabilityCarousel from '../../components/carousels/SecurityScalabilityCarousel'

function Home() {
  const { t } = useTranslation('home')

  return (
    <div className="home">
      <Header />
      <MainBanner />
      <SolutionCarousel />
      <Punchline
        title={t('Data quality')}
        desc={t(
          'A good-quality, reliable and well-structured database remains an absolute must-have',
        )}
        background="punchline_2.jpg"
      />
      <SecureDataFlowSection />
      <PropositionSection />
      <SecurityScalabilityCarousel />
      <Punchline
        title={t('Secure conversations and data flows')}
        desc={t('The 6 pillars')}
        background="punchline.jpg"
      />
      <ListCards />
      <Punchline
        title={t('Scientific partnerships and innovation')}
        desc={t('Be associated with world firsts')}
        background="punchline_3.jpg"
      />
      <PartnershipCarousel />
      <Footer />
    </div>
  )
}

export default Home
