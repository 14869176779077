import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageSwitch } from '../language-switch/LanguageSwitch'
import './navbar.scss'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { setVisible } from '../contact-form/demandContactSlice'
import { useLocation } from 'react-router-dom'

function Navbar() {
  const { t } = useTranslation('home')
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()

  const isActiveLink = (link: string) => {
    return location.pathname === link
  }

  return (
    <ul className="navbar">
      <li className={isActiveLink('/home') ? 'active' : ''}>
        <a href="/home">{t('Home')}</a>
      </li>
      <li className="hideOnMobile">|</li>
      <li className={isActiveLink('/about-us') ? 'active' : ''}>
        <a href="/about-us">{t('About')}</a>
      </li>
      <li className="hideOnMobile">|</li>
      <li className={isActiveLink('/investors') ? 'active' : ''}>
        <a href="/investors">{t('Investors')}</a>
      </li>
      <li className="hideOnMobile">|</li>
      <li>
        <a onClick={() => dispatch(setVisible(true))}>{t('Contact us')}</a>
      </li>
      <li className="hideOnMobile">|</li>
      <li>
        <LanguageSwitch />
      </li>
    </ul>
  )
}

export default Navbar
