import React, { ReactElement, useRef, useState } from 'react'
import { motion, AnimatePresence, useInView } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'
import './list-cards.scss'
import { MdOutlineClose } from 'react-icons/md'
import { Card } from 'antd'
import Meta from 'antd/es/card/Meta'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa6'

export interface Item {
  cardTitle: ReactElement
  title: ReactElement
  img: string[]
  expended_img?: string[]
  desc: ReactElement[]
  complementary?: ReactElement
  misc?: ReactElement
}

function ListCards() {
  const { t } = useTranslation('home')
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)

  const items: Item[] = [
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Information assets"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans
          ns="list-cards"
          i18nKey="Information assets"
          components={{ bold: <strong /> }}
        />
      ),
      img: ['/images/patrimoine.jpg'],
      expended_img: ['/images/patrimoine_2.png'],
      desc: [
        <p key="info-assets">
          <Trans
            ns="list-cards"
            i18nKey="INFORMATION_ASSETS"
            components={{ bold: <strong /> }}
          />
        </p>,
        <p key="acquisition">
          <Trans
            ns="list-cards"
            i18nKey="ACQUISITION"
            components={{ bold: <strong /> }}
          />
        </p>,
      ],
    },
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Data governance"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans
          ns="list-cards"
          i18nKey="The impact of risks and regulations on organisations"
          components={{ bold: <strong /> }}
        />
      ),
      img: ['/images/data_quality.jpg'],
      expended_img: ['/images/data_quality_2.png'],
      desc: [
        <p key="it-managers">
          <Trans
            ns="list-cards"
            i18nKey="IT_MANAGERS"
            components={{ bold: <strong /> }}
          />
        </p>,
        <p key="quality">
          <Trans ns="list-cards" i18nKey="QUALITY" components={{ bold: <strong /> }} />
        </p>,
      ],
    },
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Data usability"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans
          ns="list-cards"
          i18nKey="The usability of data for the explicability of artificial intelligence"
          components={{ bold: <strong /> }}
        />
      ),
      img: ['/images/ai.jpg'],
      expended_img: ['/images/ai.jpg'],
      desc: [
        <p key="ai">
          <Trans ns="list-cards" i18nKey="AI" components={{ bold: <strong /> }} />
        </p>,
        <p key="data-quality">
          <Trans
            ns="list-cards"
            i18nKey="DATA_QUALITY"
            components={{ bold: <strong /> }}
          />
        </p>,
        <p key="danger">
          <Trans ns="list-cards" i18nKey="DANGER" components={{ bold: <strong /> }} />
        </p>,
      ],
    },
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Regulation & innovation"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans
          ns="list-cards"
          i18nKey="Regulations drive innovation, differentiation and confidence"
          components={{ bold: <strong /> }}
        />
      ),
      img: ['/images/regulation.jpg'],
      expended_img: ['/images/compliance.jpg'],
      desc: [
        <p key="regulatory-culture">
          <Trans
            ns="list-cards"
            i18nKey="REGULATORY_CULTURE"
            components={{ bold: <strong /> }}
          />
        </p>,
        <p key="heritage">
          <Trans ns="list-cards" i18nKey="HERITAGE" components={{ bold: <strong /> }} />
        </p>,
        <p key="regulation">
          <Trans
            ns="list-cards"
            i18nKey="REGULATION_BEGIN"
            components={{ bold: <strong /> }}
          />{' '}
          (
          <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="blank">
            {t('GDPR')}
          </a>
          ,{' '}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A52022PC0197"
            target="blank"
          >
            {t('EHDS')}
          </a>
          ,{' '}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:52017PC0010"
            target="blank"
          >
            {t('ePrivacy')}
          </a>
          ,{' '}
          <a href="https://artificialintelligenceact.eu/fr/" target="blank">
            {t('AI Act')}
          </a>
          ,{' '}
          <a
            href="https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32022R0868"
            target="blank"
          >
            {t('DGA act')}
          </a>
          ,{' '}
          <a href="https://eur-lex.europa.eu/eli/reg/2022/2554/oj" target="blank">
            {t('DORA act')}
          </a>
          ,{' '}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A52022PC0454"
            target="blank"
          >
            {t('CRA act')}
          </a>
          ,{' '}
          <a
            href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32022L2555"
            target="blank"
          >
            {t('NIS2')}
          </a>
          *){' '}
          <Trans
            ns="list-cards"
            i18nKey="REGULATION_END"
            components={{ bold: <strong /> }}
          />
        </p>,
      ],
      complementary: (
        <Trans
          ns="list-cards"
          i18nKey="(* Non exhaustive)"
          components={{ italic: <i /> }}
        />
      ),
    },
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Data sovereignty"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans ns="list-cards" i18nKey="Sovereignty" components={{ bold: <strong /> }} />
      ),
      img: ['/images/sovereignty.jpg'],
      expended_img: ['/images/sovereignty.jpg'],
      desc: [
        <p key="countries">
          <Trans ns="list-cards" i18nKey="COUNTRIES" components={{ bold: <strong /> }} />
        </p>,
        <p key="data">
          <Trans ns="list-cards" i18nKey="DATA" components={{ bold: <strong /> }} />
        </p>,
        <p key="regulations">
          <Trans
            ns="list-cards"
            i18nKey="REGULATIONS"
            components={{ bold: <strong /> }}
          />
        </p>,
        <p key="cybersecurity">
          <Trans
            ns="list-cards"
            i18nKey="CYBERSECURITY"
            components={{ bold: <strong /> }}
          />
        </p>,
      ],
    },
    {
      cardTitle: (
        <Trans
          ns="list-cards"
          i18nKey="Sustainable investment"
          components={{ bold: <strong /> }}
        />
      ),
      title: (
        <Trans
          ns="list-cards"
          i18nKey="Sustainable investment"
          components={{ bold: <strong /> }}
        />
      ),
      img: ['/images/invest.jpg'],
      expended_img: ['/images/invest.jpg'],
      desc: [
        <p key="sovereign">
          <Trans ns="list-cards" i18nKey="SOVEREIGN" components={{ bold: <strong /> }} />
        </p>,
        <p key="european-regulations">
          <Trans
            ns="list-cards"
            i18nKey="EUROPEAN_REGULATIONS"
            components={{ bold: <strong /> }}
          />
        </p>,
      ],
      misc: (
        <Trans
          ns="list-cards"
          i18nKey="Companies must be able to assess and communicate their social and environmental impact."
          components={{ bold: <strong />, italic: <i /> }}
        />
      ),
    },
  ]

  return (
    <section
      className={`list-cards section-content ${selectedItem ? 'list-cards--opaque' : ''}`}
    >
      <div className={`backdrop ${selectedItem ? 'backdrop-visible' : ''}`}></div>
      {items.map((item: Item, index: number) => {
        const ref = useRef(null)
        const isInView = useInView(ref)

        return (
          <div
            key={index}
            className={`card-container ${isInView ? 'visible' : ''}`}
            ref={ref}
          >
            <Card
              hoverable
              cover={<img src={item.img[0]} />}
              onClick={() => setSelectedItem(item)}
              className="card"
            >
              <Meta title={item.cardTitle} />
            </Card>
          </div>
        )
      })}

      <AnimatePresence>
        {selectedItem && (
          <motion.div
            className="expanded-card-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedItem(null)}
          >
            <span className="close-icon" onClick={() => setSelectedItem(null)}>
              <MdOutlineClose size="1.5em" />
            </span>
            <div className="expanded-card">
              <div className="expanded-card-content">
                <div className="img-container">
                  {selectedItem.expended_img?.map((img: string, index: number) => {
                    return <img src={img} key={index} />
                  })}
                </div>
                <div className="desc-container">
                  <h2 className="title">{selectedItem.title}</h2>
                  {selectedItem.desc.map((desc: ReactElement, index: number) => {
                    return <p key={index}>{desc}</p>
                  })}
                  {selectedItem.complementary && <i>{selectedItem.complementary}</i>}
                  {selectedItem.misc && (
                    <h3 className="center">
                      <FaQuoteLeft />
                      {selectedItem.misc}
                      <FaQuoteRight />
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  )
}

export default ListCards
