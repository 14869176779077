import React from 'react'
import './main-banner.scss'
import { useTranslation } from 'react-i18next'

function MainBanner() {
  const { t } = useTranslation('home')

  return (
    <section
      className="main-banner"
      style={{ backgroundImage: 'url("/images/banner.png")' }}
    >
      <div className="main-banner-children">
        <div className="card">
          <div className="text-container">
            <h1 className="title">
              {t('Securing the confidentiality of conversations and data flows')}
            </h1>
            <p className="desc">
              {t(
                'Auxasphere is a suite of secure collaborative solutions on an integrated sovereign platform, designed to enable organisations structure and leverage their information assets.',
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainBanner
