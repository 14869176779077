import React, { useState } from 'react'
import './footer.scss'
import Office from './Office'
import { useTranslation } from 'react-i18next'
import ContactFormModal from '../contact-form/ContactFormModal'
import { Modal } from 'antd'

function Footer() {
  const { t, i18n } = useTranslation('home')
  const [modalCookieVisible, setModalCookieVisible] = useState(false)
  return (
    <>
      <div className="footer">
        <div className="footer-main-container">
          <div className="logo-container">
            <img className="logo" src="/images/logo.png" />
          </div>
          <div className="office-container">
            <Office
              img="zurich.jpeg"
              office={t('Office Zurich')}
              company={t('Auxasphere AG')}
              building={t('Bleicherweg 10')}
              street={t('ZH-8002 Zurich')}
              city={t('Switzerland')}
            />
            <Office
              img="defense.jpeg"
              office={t('Office Paris')}
              company={t('Auxasphere SAS')}
              building={t('Tour A Cœur Défense')}
              street={t('110 Esplanade du G. de Gaulle')}
              number={t('92931 Paris La Défense')}
              city={t('France')}
            />
          </div>
        </div>
        <div className="nav">
          <a onClick={() => setModalCookieVisible(true)}>{t('Cookie policy')}</a>
          <li className="white">|</li>
          <a
            href={`/documents/legal-notice_ch_${i18n.language}.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            {t('Legal notice')}
          </a>
        </div>
      </div>
      <ContactFormModal />
      <Modal
        title={t('Cookie policy')}
        open={modalCookieVisible}
        onCancel={() => setModalCookieVisible(false)}
        footer={null}
        centered
      >
        <div>
          <p>{t('This site does not contain any cookies.')}</p>
        </div>
      </Modal>
    </>
  )
}

export default Footer
