import React, { useState, useEffect } from 'react'
import './proposition-section.scss'
import { useTranslation } from 'react-i18next'

function PropositionSection() {
  const { t } = useTranslation('home')
  const [currentBadge, setCurrentBadge] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBadge((currentBadge) => (currentBadge + 1) % 4)
    }, 1500)

    return () => clearInterval(interval)
  }, [])

  return (
    <section className="section-content proposition">
      <h1 className="title">{t('Our unique value proposition')}</h1>
      <div className="badges-container">
        <div className="badge">
          <img
            src={`${currentBadge === 0 ? '/images/r0_activate.png' : '/images/r0.png'}`}
          />
          <h2>{t('Data Usability')}</h2>
        </div>
        <img className="connection" src="/images/connection.png" />
        <div className="badge">
          <img
            src={`${currentBadge === 1 ? '/images/r1_activate.png' : '/images/r1.png'}`}
          />
          <h2>{t('Sovereignty')}</h2>
        </div>
        <img className="connection" src="/images/connection.png" />
        <div className="badge">
          <img
            src={`${currentBadge === 2 ? '/images/r2_activate.png' : '/images/r2.png'}`}
          />
          <h2 className={currentBadge === 2 ? 'active' : ''}>
            {t('Private Environment')}
          </h2>
        </div>
        <img className="connection" src="/images/connection.png" />
        <div className="badge">
          <img
            src={`${currentBadge === 3 ? '/images/r3_activate.png' : '/images/r3.png'}`}
          />
          <h2 className={currentBadge === 3 ? 'active' : ''}>
            {t('Integrated Platform')}
          </h2>
        </div>
      </div>
    </section>
  )
}

export default PropositionSection
