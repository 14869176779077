import React from 'react'
import './security-scalability-carousel.scss'
import { Trans, useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'

function SecurityScalabilityCarousel() {
  const { t } = useTranslation('security-scalability')

  return (
    <section className="security-scalability-carousel section-carousel">
      <Carousel
        animationHandler="fade"
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        emulateTouch={false}
      >
        <div className="security-scalability-carousel-children">
          <div className="legend">
            <div className="img-container d-flex-center">
              <img className="shield" src="/images/shield.jpeg" />
            </div>
            <div className="text-container">
              <h1 className="main-title">
                {t('Our approach to security and scalability')}
              </h1>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="security-scalability"
                    i18nKey="INTEGRATED_PLATFORM"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  {t(
                    'This approach enables monitoring, resilience, scalability, agility and upgradability.',
                  )}
                </p>
                <p className="desc">
                  <Trans
                    ns="security-scalability"
                    i18nKey="PRIVATE_ENV"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <ul className="list">
                  <li>{t('An independent operating system (Linux)')}</li>
                  <li>{t('Sovereign application suites with no third-party APIs')}</li>
                  <li>{t('Decentralised data management')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="security-scalability-carousel-children">
          <div className="legend">
            <div className="img-container d-flex-center">
              <img className="shield" src="/images/shield.jpeg" />
            </div>
            <div className="text-container">
              <h1 className="main-title">
                {t('Our approach to security and scalability')}
              </h1>
              <div className="desc-container">
                <p className="desc mb-1em">
                  <Trans
                    ns="security-scalability"
                    i18nKey="HIGH_LEVEL_SECURITY"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  <Trans
                    ns="security-scalability"
                    i18nKey="HDS"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc">
                  <Trans
                    ns="security-scalability"
                    i18nKey="HIGH_AVAILABILITY"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="security-scalability-carousel-children">
          <div className="legend">
            <div className="img-container d-flex-center">
              <img className="shield" src="/images/shield.jpeg" />
            </div>
            <div className="text-container">
              <h1 className="main-title">
                {t('Our approach to security and scalability')}
              </h1>
              <div className="desc-container">
                <p className="desc mb-1em">
                  <Trans
                    ns="security-scalability"
                    i18nKey="EUCS"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  <Trans
                    ns="security-scalability"
                    i18nKey="EUROPEAN_CERTIF"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc">
                  <Trans
                    ns="security-scalability"
                    i18nKey="HARMONISE_CYBER"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  )
}

export default SecurityScalabilityCarousel
