import React from 'react'
import { useTranslation } from 'react-i18next'
import { setVisible } from '../contact-form/demandContactSlice'
import { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

interface Props {
  img: string
  office: string
  company: string
  building: string
  number?: string
  street: string
  city: string
}

function Office({ img, office, company, building, number, street, city }: Props) {
  const { t } = useTranslation('home')
  const dispatch = useDispatch<AppDispatch>()

  return (
    <div className="office">
      <div className="img-container">
        <img src={`/images/${img}`} />
      </div>
      <div className="text-container">
        <div>
          <p className="bold">{office}</p>
          <p>{company}</p>
          <p>{building}</p>
          <p>{street}</p>
          {number && <p>{number}</p>}
          <p>{city}</p>
        </div>
        <p className="title" onClick={() => dispatch(setVisible(true))}>
          {t('Contact us')}
        </p>
      </div>
    </div>
  )
}

export default Office
