import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import Navbar from './Navbar'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer } from 'antd'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { setVisible } from '../contact-form/demandContactSlice'
import { LanguageSwitch } from '../language-switch/LanguageSwitch'
import { CloseOutlined } from '@ant-design/icons'
import './header.scss'

function Header() {
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation('home')
  const dispatch = useDispatch<AppDispatch>()

  const showDrawer = () => {
    setOpen(true)
  }
  const isActiveLink = (link: string) => {
    return location.pathname === link
  }

  const onClose = () => {
    setOpen(false)
  }
  function renderDrawer() {
    return (
      <div className="hideOnDesktop">
        <AiOutlineMenu className="menu-burger" size="2em" onClick={showDrawer} />
        <Drawer
          className="custom-drawer"
          width="18rem"
          placement="right"
          onClose={onClose}
          open={open}
          closeIcon={<CloseOutlined className="close-icon-menu" />}
          style={{
            backgroundColor: '#033f61',
          }}
        >
          <Navbar />
        </Drawer>
      </div>
    )
  }

  return (
    <header className="header">
      <div className="navigation hideOnMobile">
        <Navbar />
      </div>
      {renderDrawer()}
      <div className="logo-container">
        <img src="/images/logo.png" alt="logo" className="logo" />
      </div>
    </header>
  )
}

export default Header
