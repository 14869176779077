import React from 'react'
import './secure-data-flow-section.scss'
import { Trans, useTranslation } from 'react-i18next'

function SecureDataFlowSection() {
  const { t } = useTranslation('secure-data-flow')

  return (
    <section className="section-content section-content--flex secure-data-flow">
      <div className="left">
        <img className="parcours" src="/images/parcours.jpg" />
        <img className="badge" src="/images/notarisation.png" />
      </div>
      <div className="right">
        <h1>{t('Our approach to secure data flows')}</h1>
        <div className="desc-container">
          <p>
            <Trans
              ns="secure-data-flow"
              i18nKey="ANALOGY_PAYMENT"
              components={{ bold: <strong /> }}
            />
          </p>
          <p>
            <Trans
              ns="secure-data-flow"
              i18nKey="MULTIMODAL"
              components={{ bold: <strong /> }}
            />
          </p>
          <p>
            <Trans
              ns="secure-data-flow"
              i18nKey="AUXASPHERE_DATA"
              components={{ bold: <strong /> }}
            />
          </p>
          <p>
            <Trans
              ns="secure-data-flow"
              i18nKey="AUXASPHERE_DELIVERY"
              components={{ bold: <strong /> }}
            />
          </p>
          <p>
            <Trans
              ns="secure-data-flow"
              i18nKey="AUXASPHERE_SOLUTIONS"
              components={{ bold: <strong /> }}
            />
          </p>
        </div>
        <div className="strengths-container">
          <div className="strengths-text-container">
            <h1>{t('Our strengths')}</h1>
            <div className="desc-container">
              <ul className="list">
                <li>{t('Security & privacy by design')}</li>
                <li>{t('Autonomous modular application universe')}</li>
                <li>{t('Segmented architecture')}</li>
                <li>{t('Scalable distributed infrastructure')}</li>
              </ul>
            </div>
          </div>
          <div className="strengths-img-container">
            <img className="strengths-img" src="/images/strength.png" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecureDataFlowSection
