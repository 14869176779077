import React from 'react'
import './solution-carousel.scss'
import { Carousel } from 'react-responsive-carousel'
import { Trans, useTranslation } from 'react-i18next'

function SectionCarousel() {
  const { t } = useTranslation('home')

  return (
    <section className="solution-carousel section-carousel">
      <Carousel
        animationHandler="fade"
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        autoPlay
        stopOnHover
        swipeable={false}
        emulateTouch={false}
      >
        <div className="solution-carousel-children">
          <div className="legend">
            <div className="img-container">
              <img src="/images/solution.jpg" />
            </div>
            <div className="text-container">
              <h1 className="main-title">{t('Our solutions')}</h1>
              <h2 className="sub-title">{t('Our secure collaborative solutions')}</h2>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE_TOOLS"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE_TRACABILITY"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc">
                  {t(
                    'Auxasuite is multilingual. The languages most used to date are English, French, German, Italian and Ukrainian.',
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="solution-carousel-children">
          <div className="legend">
            <div className="img-container">
              <img src="/images/solution_2.jpg" />
            </div>
            <div className="text-container">
              <h1 className="main-title">{t('Our missions')}</h1>
              <h2 className="sub-title">{t('Develop digital assets')}</h2>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE_MODULABLE"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
              <h2 className="sub-title">{t('Enable data usability')}</h2>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE_SECURE_DATAFLOW"
                    components={{ bold: <strong /> }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="solution-carousel-children">
          <div className="legend">
            <div className="img-container">
              <img className="auxasuite" src="/images/auxasuite.png" />
              <img src="/images/auxamed.png" />
            </div>
            <div className="text-container">
              <h1 className="main-title">{t('Our customer solutions')}</h1>
              <div className="desc-container">
                <p className="desc">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc mb-1em">
                  <Trans
                    ns="home"
                    i18nKey="AUXASUITE_REGULATION"
                    components={{ bold: <strong /> }}
                  />
                </p>
                <p className="desc">
                  <Trans ns="home" i18nKey="AUXAMED" components={{ bold: <strong /> }} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </section>
  )
}

export default SectionCarousel
